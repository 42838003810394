<template>
<div >
    <v-navigation-drawer id="admin-drawer" v-model="drawer" :dark="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'" disable-resize-watcher :right="$vuetify.rtl" :src="barImage" app width="210" v-bind="$attrs">
        <v-btn fab x-small absolute text right v-if="drawer" @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : setDrawer(!drawer)" v-tippy title="Ocultar Barra Lateral">
            <v-icon x-small gray--text>mdi-arrow-expand-left</v-icon>
        </v-btn>

        <template v-slot:img="props">
            <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
        </template>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="text-uppercase font-weight-regular display-2">
                    <span class="logo-normal">
                        <img src="/static/logo_blanco.png" height="80" alt="proControl" />
                    </span>
                </v-list-item-title>

            </v-list-item-content>

        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="font-weight-black">{{nombre_usuario}}</v-list-item-title>
                <v-list-item-subtitle class="caption">{{tipo_usuario}}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="sucursal_usuario != 0" class="caption">{{$local_storage.get("sb_nombre_sucursal")}}</v-list-item-subtitle>



            </v-list-item-content>

        </v-list-item>
        <div class="pl-4 pr-4">
             <v-autocomplete  v-if="sucursal_usuario == 0" v-model="sucursal_actual" item-value= "_id"
             :items="sucursales" :hide-no-data="true" :hide-selected="true"  v-on:change="actualiza_sucursal"
              item-text="nombre" dense  hint="SUCURSAL" persistent-hint return-object>
            </v-autocomplete>
        </div>

    </v-navigation-drawer>

    <p />
    <p />
</div>
</template>

<script>
import {
    mapState,
    mapMutations
}

from "vuex";

import {
    VHover,
    VListItem
}

from "vuetify/lib";

import axios from "axios";

export default {

    name: "DrawerAdmin",
    components: {
        AppBarItem: {
            render(h) {
                return h(VHover, {
                        scopedSlots: {
                            default: ({
                                    hover
                                }

                            ) => {
                                return h(VListItem, {

                                        attrs: this.$attrs,
                                        class: {
                                            "black--text": !hover,
                                            "white--text secondary elevation-12": hover
                                        }

                                        ,
                                        props: {
                                            activeClass: "",
                                            dark: hover,
                                            link: true,
                                            ...this.$attrs
                                        }
                                    }

                                    ,
                                    this.$slots.default);
                            }
                        }
                    }

                );
            }
        }
    }

    ,
    props: {
        expandOnHover: {
            type: Boolean,
            default: false
        }
    }

    ,

    data: () => ({
            dialog: false,
            version: 0.00,
            notas: [],
            fab: false,
            tipo_usuario: "",
            nombre_usuario: "",

            fav: true,
            menu: false,
            message: false,
            hints: true,
            sucursales:[],
            sucursal_actual: "",
            sucursal_usuario: "",
        }

    ),

    computed: {

        ...mapState(["barColor", "barImage", "drawer"]),
        drawer: {
            get() {
                    return this.$store.state.drawer;
                }

                ,
            set(val) {
                this.$store.commit("SET_DRAWER", val);
            }
        } ,
        computedItems() {
            return this.items.map(this.mapItem);
        } ,

    }

    ,

    watch: {
        "$vuetify.breakpoint.smAndDown"(val) {
            this.$emit("update:expandOnHover", !val);
        }
    }

    ,
    created() {

        this.sucursal_usuario = this.$local_storage.get("sb_sucursal_usuario");

    }

    ,
    mounted() {
        this.carga_datos();
    }

    ,
    methods: {
        ...mapMutations({
                setDrawer: "SET_DRAWER"
            }

        ),
        mapItem(item) {
            return {
                ...item,
                children: item.children ? item.children.map(this.mapItem) : undefined,
                title: this.$t(item.title)
            }

            ;
        },
        actualiza_sucursal(selectObj) {

           this.$local_storage.set("sb_sucursal", selectObj._id);
           this.$local_storage.set("sb_nombre_sucursal", selectObj.nombre);
           this.$store.state.sucursal =  selectObj._id;

           if(selectObj._id != 0){                
                this.$user.set({role: "sucursal" });
                this.$local_storage.set("sb_role", "sucursal");
                 if (this.$store.state.current_url != '/index')
                     this.$router.go();
            } else{                
                this.$user.set({role: "admin" });
                this.$local_storage.set("sb_role", "admin");
            }

        },
        get_sucursales: function () {

            let data = {
                "selector": {
                    "type":"sucursales",
                    "estatus": {
                        "$eq": "Activo"
                    }
                },
                //"sort": ["nombre"],
                "fields": [
                    "nombre", "_id"
                ],
                "use_index": "_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    this.sucursales = [];
                    if (response.data.docs.length > 0)
                        response.data.docs.sort(this.ordenar_nombre);
                        this.sucursales = response.data.docs;
                        let todas = {
                            _id: '0',
                            nombre: 'TODAS'
                        };
                        this.sucursales.unshift(todas);

                        window.sucursales = this.sucursales;

                        if(this.$local_storage.get("sb_sucursal") == 0){
                            this.sucursal_actual = todas;
                        }else{
                            this.sucursal_actual = {
                                        _id: this.$local_storage.get("sb_sucursal"),
                                        nombre: this.$local_storage.get("sb_nombre_sucursal")
                                    };
                        }



                })

                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las sucursales.",
                        footer: ""
                    });
                });
        },
        ordenar_nombre: function( a, b ){
            if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
                return -1;
            }
            if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
                return 1;
            }
            return 0;
        },
        carga_datos() {
            this.nombre_usuario = this.$local_storage.get("sb_nombre_usuario");
            this.tipo_usuario = this.$local_storage.get("sb_perfil");
            if (this.$local_storage.get("sb_sucursal_usuario") == 0){
                this.get_sucursales();
            }

        },
        genChildTarget(item, subItem) {
            if (subItem.href) return;

            if (subItem.component) {
                return {
                    name: subItem.component
                }

                ;
            }
            return {
                name: `$ {
                    item.group
                }

                /$ {
                    subItem.name
                }

                `
            }

            ;
        }
    }
}

;
</script>

<style scoped>
.footer {
    text-align: left;
    position: fixed;
    bottom: 0;
    height: 15px;

    color: #0E4D78;
    background-color: #f5f5f5;
    z-index: 100000;
    font-size: 7pt;
}

.v-menu__content .v-list--nav .v-list-item {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    text-decoration: none;
}

.v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab) {
    padding-left: 10px !important;
}
.v-select >>> input {
    font-size: 0.7em;
}
.v-select >>> label {
    font-size: 0.6em;
}
.v-select >>> button {
    font-size: 0.7em;
}
</style>
